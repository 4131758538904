import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Meta from "../components/meta"
import Header from "../components/header"
import Button from "../components/button"

const Container = styled.section`
  ${props => props.theme.gridContainer()}

  margin-bottom: ${rem(80)};
  text-align: center;
`

const Title = styled.h1`
  ${props => props.theme.ffSecondarySet()}

  color: ${props => props.theme.colorYellow};
  font-size: ${rem(92)};
  word-break: break-word;
`

const Content = styled.div`
  margin-bottom: ${rem(40)};
`

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Meta title="Page not found" />

      <Header />

      <Container>
        <Title>Not found</Title>

        <Content className="styled">
          <p>Couldn't find the page you looked for...</p>
        </Content>

        <Button to="/">Back to Home</Button>
      </Container>
    </React.Fragment>
  )
}

export default NotFoundPage
